import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  FetchProfilesResponse,
  FetchTournamentsResponse,
  createTournament,
  fetchProfiles,
  fetchTournaments,
  supabase,
  upsertGames,
  upsertPlayers,
} from '../clients/supabaseClient'
import { Tournament } from '../types'

export const supabaseApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Tournament'],
  endpoints: (builder) => ({
    getTournaments: builder.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('tournaments')
          .select('id,title,players(name,id,user)')

        if (error) {
          return { error }
        }
        return { data }
      },
      providesTags: ['Tournament'],
    }),

    getTournament: builder.query({
      queryFn: async ({ id }: { id: string }) => {
        const { data, error } = await supabase
          .from('tournaments')
          .select('id,title,players(name,id,user)')
          .eq('id', id)

        if (error) {
          return { error }
        }
        return { data: data[0] }
      },
      providesTags: ['Tournament'],
    }),

    getUsers: builder.query({
      queryFn: async () => {
        const { data, error } = await fetchProfiles()
        if (error) {
          return { error }
        }
        return { data }
      },
    }),

    createTournament: builder.mutation<
      string,
      Parameters<typeof createTournament>[0]
    >({
      queryFn: async (arg) => {
        const { data, error } = await createTournament(arg)
        console.debug('Created tournament', { data, error })
        if (error) return { error }
        return { data: data[0].id }
      },
      invalidatesTags: ['Tournament'],
    }),

    upsertPlayers: builder.mutation<
      boolean,
      Parameters<typeof upsertPlayers>[0]
    >({
      queryFn: async (arg) => {
        const { data, error, count } = await upsertPlayers(arg)
        console.debug('Upserted players', { data, error, count })
        if (error) return { error }
        return { data: true }
      },
      invalidatesTags: ['Tournament'],
    }),

    upsertGames: builder.mutation<boolean, Parameters<typeof upsertGames>[0]>({
      queryFn: async (arg) => {
        const { data, error, count } = await upsertGames(arg)
        console.debug('Upserted games', { data, error, count })
        if (error) return { error }
        return { data: true }
      },
      invalidatesTags: ['Tournament'],
    }),
  }),
})

export const useGetTournamentsQuery = () =>
  supabaseApi.useGetTournamentsQuery({})

export const useGetUsersQuery = () => supabaseApi.useGetUsersQuery({})

export const {
  useCreateTournamentMutation,
  useGetTournamentQuery,
  useUpsertGamesMutation,
} = supabaseApi
