import { configureStore } from '@reduxjs/toolkit'
import sessionSlice from './sessionSlice'
import { supabaseApi } from '../api'

export const store = configureStore({
  reducer: {
    session: sessionSlice,
    [supabaseApi.reducerPath]: supabaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(supabaseApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
