import { useAppDispatch, useAppSelector } from '../store/hooks'
import { setSession as setSessionAction } from '../store/sessionSlice'
import { useCallback } from 'react'
import { Session } from '@supabase/supabase-js'

export const useSession = () => {
  const dispatch = useAppDispatch()
  const setSession = useCallback(
    (session: Session | null) => dispatch(setSessionAction(session)),
    [dispatch]
  )
  const session = useAppSelector((state) => state.session.session)
  return [session, setSession] as const
}
