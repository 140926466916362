import { Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useCallback } from 'react'
import { Handle, Position } from 'reactflow'

const handleStyle = { left: 10 }

export const TournamentNode = ({ data }: { data: any }) => {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Paper sx={{ padding: 0.5 }}>
        <Stack spacing={1}>
          <Paper sx={{ padding: 0.5 }}>
            <Typography>{data.label}</Typography>
          </Paper>
          <Paper sx={{ padding: 0.5 }}>
            <Typography>{data.label}</Typography>
          </Paper>
        </Stack>
      </Paper>
      <Handle type="source" position={Position.Right} />
    </>
  )
}
