// Function that prints
// the required sequence
export function splitInteger(x: number, n: number) {
  const result = []
  // If we cannot split the
  // number into exactly 'N' parts
  if (x < n) return []
  // If x % n == 0 then the minimum
  // difference is 0 and all
  // numbers are x / n
  else if (x % n == 0) {
    for (let i = 0; i < n; i++) result.push(x / n)
  } else {
    // upto n-(x % n) the values
    // will be x / n
    // after that the values
    // will be x / n + 1
    const zp = n - (x % n)
    const pp = Math.floor(x / n)
    for (let i = 0; i < n; i++) {
      if (i >= zp) result.push(pp + 1)
      else result.push(pp)
    }
  }
  return result
}
