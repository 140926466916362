import { createClient } from '@supabase/supabase-js'
import { Database } from '../types'

const _supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const _supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient<Database>(
  'https://fvpxhmrzvnegnlpculrw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ2cHhobXJ6dm5lZ25scGN1bHJ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTMwNTM2NjgsImV4cCI6MjAwODYyOTY2OH0.SX0hFI0Yv7Q-IYiEaSueZdMrj4jb4betSe9t2MtGgIQ'
)

export const fetchTournaments = () =>
  supabase.from('tournaments').select(`
id,
title,
players ( name)
`)

export type FetchTournamentsResponse = Awaited<
  ReturnType<typeof fetchTournaments>
>['data']
export type Tournament = NonNullable<FetchTournamentsResponse>[number]

export const fetchProfiles = () =>
  supabase.from('profiles').select(`
id,
username,
full_name,
avatar_url
`)

export type FetchProfilesResponse = Awaited<
  ReturnType<typeof fetchProfiles>
>['data']
export type Profile = NonNullable<FetchProfilesResponse>[number]

export const createTournament = ({ title }: { title: string }) =>
  supabase.from('tournaments').upsert({ title }).select('id')

export const upsertPlayers = ({
  players,
}: {
  players: Database['public']['Tables']['players']['Insert'][]
}) => supabase.from('players').upsert(players)

export const upsertGames = ({
  games,
}: {
  games: Database['public']['Tables']['games']['Insert'][]
}) => supabase.from('games').upsert(games)

export const upsertGamePlayers = ({
  gamePlayers,
}: {
  gamePlayers: Database['public']['Tables']['game_players']['Insert'][]
}) => supabase.from('game_players').upsert(gamePlayers)
