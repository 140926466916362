import * as React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../hooks'
import { supabase } from '../clients/supabaseClient'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'

// const SignInButton = () => {
//   return (
//     <Button color="inherit" onClick={() => navigate('sign-in')}>
//       Sign in
//     </Button>
//   )
// }

// const SignOutButton = () => {
//   return (
//     <Button color="inherit" onClick={() => supabase.auth.signOut()}>
//       Sign out
//     </Button>
//   )
// }

const drawerWidth = 240

export const AppBar = () => {
  const navigate = useNavigate()
  const [session] = useSession()

  const navItems = [
    { title: 'Tournaments', action: () => navigate('tournaments') },
    ...(!session
      ? [{ title: 'Sign in', action: () => navigate('sign-in') }]
      : [{ title: 'Sign out', action: () => supabase.auth.signOut() }]),
  ]
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={item.action}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
  return (
    <Box sx={{ display: 'flex' }}>
      <MuiAppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            fontFamily={'MarioFont'}
            onClick={() => navigate('/home')}
          >
            <span style={{ color: '#0B9BD7' }}>B</span>
            <span style={{ color: '#FACF07' }}>a</span>
            <span style={{ color: '#E52927' }}>r</span>
            <span style={{ color: '#45AF4A' }}>s</span>
            <span style={{ color: '#E52927' }}>b</span>
            <span style={{ color: '#45AF4A' }}>o</span>
            <span style={{ color: '#FACF07' }}>a</span>
            <span style={{ color: '#FACF07' }}>r</span>
            <span style={{ color: '#45AF4A' }}>d</span>
            <span style={{ color: '#45AF4A' }}> </span>
            <span style={{ color: '#E52927' }}>P</span>
            <span style={{ color: '#0B9BD7' }}>o</span>
            <span style={{ color: '#FACF07' }}>r</span>
            <span style={{ color: '#E52927' }}>t</span>
            <span style={{ color: '#0B9BD7' }}>a</span>
            <span style={{ color: '#FACF07' }}>l</span>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button
                color="inherit"
                variant="text"
                key={item.title}
                onClick={item.action}
              >
                {item.title}
              </Button>
            ))}
          </Box>
          <nav>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
