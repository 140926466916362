import CssBaseline from '@mui/material/CssBaseline'
import './App.css'

import { Box, CircularProgress, ThemeProvider } from '@mui/material'
import { Session } from '@supabase/supabase-js'
import React, { useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from './ProtectedRoute'
import { RootLayout } from './RootLayout'
import { supabase } from './clients/supabaseClient'
import { useSession } from './hooks'
import {
  AccountPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  Auth,
  SignInLayout,
  TournamentsPage,
  TournamentDetailsPage,
  HomePage,
} from './pages'
import { theme } from './theme'

function App() {
  const [session, setSession] = useSession()
  const [hasFetchedSession, setHasFetchedSession] = useState(false)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setHasFetchedSession(true)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      setHasFetchedSession(true)
    })
  }, [setSession])
  const router = createBrowserRouter([
    {
      element: <RootLayout />,
      children: [
        {
          element: <SignInLayout />,
          children: [
            {
              path: 'sign-in',
              element: <Auth />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPasswordPage />,
            },
          ],
        },
        {
          element: <ProtectedRoute session={session} />,
          children: [
            {
              path: '/',
              element: <HomePage />,
            },
            {
              path: 'home',
              element: <HomePage />,
            },
            {
              path: 'account',
              element: <AccountPage session={session as Session} />,
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            {
              path: 'tournaments',
              element: <TournamentsPage />,
            },
            {
              path: 'tournaments/:id',
              element: <TournamentDetailsPage />,
            },
          ],
        },
      ],
    },
  ])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Box sx={{ my: 4 }}>
          {hasFetchedSession ? (
            <RouterProvider router={router} />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
