import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: purple[500],
  //   },
  //   secondary: {
  //     main: grey[200],
  //   },
  //   background: {
  //     default: 'rgb(28,28,28)',
  //     paper: 'rgb(100,100,100)',
  //   },
  //   text: {
  //     primary: 'rgb(255,255,255)',
  //     secondary: 'rgb(255,255,255)',
  //   },
  // },
})
