import Dagre from '@dagrejs/dagre'
import React, { useCallback, useEffect, useMemo } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Position,
  useNodesInitialized,
} from 'reactflow'

import { initialNodes, initialEdges } from './nodes-edges'
import 'reactflow/dist/style.css'
import { TournamentNode } from './Node'

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

const getLayoutedElements = (nodes: any, edges: any, options: any) => {
  g.setGraph({ rankdir: options.direction })

  edges.forEach((edge: any) => g.setEdge(edge.source, edge.target))
  nodes.forEach((node: any) => g.setNode(node.id, { height: 50, width: 100 }))

  Dagre.layout(g)

  return {
    nodes: nodes.map((node: any) => {
      const { x, y } = g.node(node.id)

      return {
        ...node,
        position: { x, y },
      }
    }),
    edges,
  }
}

const LayoutFlow = () => {
  const { fitView } = useReactFlow()
  const [nodes, setNodes, onNodesChange] = useNodesState(
    initialNodes.map((n) => ({ ...n, type: 'tournamentNode' }))
  )
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const nodesInitialized = useNodesInitialized()
  const nodeTypes = useMemo(() => ({ tournamentNode: TournamentNode }), [])

  const onLayout = useCallback(
    (direction: any) => {
      const layouted = getLayoutedElements(nodes, edges, { direction })

      setNodes([...layouted.nodes])
      setEdges([...layouted.edges])

      window.requestAnimationFrame(() => {
        fitView()
      })
    },
    [nodes, edges]
  )
  useEffect(() => {
    if (nodesInitialized) {
      onLayout('LR')
    }
  }, [nodesInitialized])
  return (
    <ReactFlow
      nodeTypes={nodeTypes}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      nodesDraggable={false}
    ></ReactFlow>
  )
}

export const Flow = () => {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  )
}
