import React, { useState, useEffect, FormEvent } from 'react'
import { supabase } from '../../clients/supabaseClient'
import { Session } from '@supabase/supabase-js'
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'

export const AccountPage = ({ session }: { session: Session }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [username, setUsername] = useState<string | null>(null)
  const [fullName, setFullName] = useState<string | null>(null)
  const [avatar_url, setAvatarUrl] = useState<string | null>(null)

  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      const { user } = session

      const { data, error } = await supabase
        .from('profiles')
        .select('username, avatar_url, full_name')
        .eq('id', user.id)
        .single()

      if (error) {
        console.warn(error)
      } else if (data) {
        setUsername(data.username)
        setAvatarUrl(data.avatar_url)
        setFullName(data.full_name)
      }

      setLoading(false)
    }

    getProfile()
  }, [session])

  async function updateProfile(event: FormEvent) {
    event.preventDefault()

    setLoading(true)
    const { user } = session

    const updates = {
      id: user.id,
      username,
      avatar_url,
      full_name: fullName,
    }

    const { error } = await supabase.from('profiles').upsert(updates)

    if (error) {
      alert(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Typography variant="h1">{'Profile'}</Typography>
      <Box>
        <Stack spacing={4} width="100%">
          <Box>
            <InputLabel>Username</InputLabel>
            <Input value={session.user.email} disabled />
          </Box>
          <Box>
            <InputLabel>Username</InputLabel>
            <Input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
          </Box>
          <Box>
            <InputLabel>Full name</InputLabel>
            <Input
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
            />
          </Box>
          <ButtonGroup>
            <Button onClick={updateProfile}>Save</Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </>
  )
}
