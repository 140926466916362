import { Button, ButtonGroup, Input, Stack } from '@mui/material'
import React, { useState } from 'react'
import { supabase } from '../../clients/supabaseClient'

export const ResetPasswordPage = () => {
  const [_loading, setLoading] = useState(false)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const handleLogin = async () => {
    if (password1 !== password2) {
      alert('Passwords do not match')
      return
    }
    setLoading(true)
    const { error } = await supabase.auth.updateUser({ password: password1 })

    if (error) {
      alert(error.message)
    } else {
      alert('Password changed!')
    }
    setLoading(false)
  }

  return (
    <>
      <Stack spacing={4}>
        <Input
          onChange={(e) => setPassword1(e.target.value)}
          placeholder="Enter new password"
          value={password1}
          type="password"
          required
        />
        <Input
          onChange={(e) => setPassword2(e.target.value)}
          placeholder="Re-enter new password"
          value={password2}
          type="password"
          required
        />
        <ButtonGroup>
          <Button onClick={handleLogin}>Update password</Button>
        </ButtonGroup>
      </Stack>
    </>
  )
}
