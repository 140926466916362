import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputLabel,
  Stack,
} from '@mui/material'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { supabase } from '../../clients/supabaseClient'
import { useSession } from '../../hooks'

export const Auth = () => {
  const [_loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [session] = useSession()

  const handleLogin = async () => {
    setLoading(true)
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    if (error) {
      alert(error.message)
    } else {
      console.log('Login successful', data)
    }
    setLoading(false)
  }

  const navigate = useNavigate()

  if (session) return <Navigate to="/home" />

  return (
    <>
      <Stack spacing={4}>
        <Box>
          <InputLabel>Email</InputLabel>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="you@jbb.life"
            value={email}
            required
          />
        </Box>
        <Box>
          <InputLabel>Password</InputLabel>
          <Input
            onChange={(e) => setPassword(e.target.value)}
            placeholder="*********"
            value={password}
            type="password"
          />
        </Box>
        <ButtonGroup>
          <Button onClick={handleLogin}>Sign in</Button>
          <Button onClick={() => navigate('/forgot-password')}>
            Forgot password
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  )
}
