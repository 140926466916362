import { Box, Container } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppBar } from './components/AppBar'

export const RootLayout = () => (
  <>
    <Container maxWidth="lg">
      <Box sx={{ marginBottom: 8 }}>
        <AppBar />
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Container>
  </>
)
