import React, { useState } from 'react'
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  Stack,
  Box,
  InputLabel,
  Input,
  DialogActions,
  Button,
  Typography,
  Paper,
  IconButton,
} from '@mui/material'
import { useCreateTournamentMutation, useGetUsersQuery } from '../../hooks'
import { theme } from '../../theme'
import { Add } from '@mui/icons-material'
import { Profile, upsertPlayers } from '../../clients/supabaseClient'

type Player = {
  name: string
  user?: Profile
}

export const TournamentModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const [createTournament] = useCreateTournamentMutation()
  const users = useGetUsersQuery()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [tournamentName, setTournamentName] = useState('')
  const [players, setPlayers] = useState<Player[]>([{ name: '' }])
  const handleSave = async () => {
    const createTournamentResult = await createTournament({
      title: tournamentName,
    })
    if ('error' in createTournamentResult) {
      alert('Something went wrong creating the tournament')
      return
    }
    await upsertPlayers({
      players: players.map((p) => ({
        tournament: createTournamentResult.data,
        name: p.name,
        user: p.user?.id,
      })),
    })
    onClose()
  }
  const handleAddPlayer = (user?: Player['user']) => {
    setPlayers((prev) => [...prev, { name: user?.full_name || '', user }])
  }

  const handlePlayerUpdate = (index: number, player: Player) => {
    setPlayers((prev) => {
      const updatePlayers = [...prev]
      updatePlayers[index] = player
      return updatePlayers
    })
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { width: '500px' } }}
    >
      <DialogContent>
        <Stack spacing={4}>
          <Box>
            <InputLabel>Title</InputLabel>
            <Input
              onChange={(e) => setTournamentName(e.target.value)}
              value={tournamentName}
              fullWidth
            />
          </Box>
          <Box>
            <Stack spacing={2}>
              <Typography variant="h5">Players</Typography>
              {players.map((player, index) => (
                <Paper
                  sx={{
                    padding: 2,
                  }}
                  key={index}
                >
                  <InputLabel>Name</InputLabel>
                  <Input
                    value={player.name}
                    fullWidth
                    onChange={(e) =>
                      handlePlayerUpdate(index, {
                        ...player,
                        name: e.target.value,
                      })
                    }
                  ></Input>
                </Paper>
              ))}
              <Button variant="contained" onClick={() => handleAddPlayer()}>
                +
              </Button>
              <Typography variant="h6">Quick add</Typography>
              {users.data?.map((user) => (
                <Stack direction="row" key={user.id}>
                  <Paper sx={{ padding: 2, display: 'inline' }}>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                      <Typography>{user.full_name}</Typography>
                      <IconButton onClick={() => handleAddPlayer(user)}>
                        <Add />
                      </IconButton>
                    </Stack>
                  </Paper>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
