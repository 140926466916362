import { Button, ButtonGroup, Input, Stack } from '@mui/material'
import React, { useState } from 'react'
import { supabase } from '../../clients/supabaseClient'

export const ForgotPasswordPage = () => {
  const [_loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleResetPassword = async () => {
    setLoading(true)
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'http://localhost:3000/reset-password',
    })

    if (error) {
      alert(error.message)
    } else {
      alert('Check your email for the login link!')
    }
    setLoading(false)
  }

  return (
    <>
      <Stack spacing={4}>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          value={email}
          required
        />
        <ButtonGroup>
          <Button onClick={handleResetPassword}>Reset password</Button>
        </ButtonGroup>
      </Stack>
    </>
  )
}
