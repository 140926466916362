import React, { useCallback } from 'react'
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  Button,
} from '@mui/material'
import { useGetTournamentQuery } from '../../hooks'
import { useParams } from 'react-router-dom'

import 'reactflow/dist/style.css'
import { Flow } from '../../components/Flow'
import { Player, Tournament } from '../../types'
import { useUpsertGamesMutation } from '../../api'
import { splitInteger } from '../../util/splitInteger'

export const TournamentDetailsPage = () => {
  const { id: maybeId } = useParams()
  const id = maybeId ?? ''
  const { data: tournament, isLoading, isError } = useGetTournamentQuery({ id })
  const setupGames = useSetupGames(tournament)
  const handleClickSetup = useCallback(() => setupGames(), [setupGames])

  if (!id) {
    return <Typography>No tournament id was specified</Typography>
  }

  if (isLoading) {
    return <Typography>Loading...</Typography>
  }

  if (isError || !tournament) {
    return <Typography>Error fetching tournament details</Typography>
  }

  return (
    <Container>
      <Typography variant="h1">{tournament.title}</Typography>
      <Box textAlign="end">
        <Button variant="contained" onClick={handleClickSetup}>
          Setup
        </Button>
      </Box>
      <Stack spacing={5}>
        <Paper sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom>
            Players
          </Typography>
          <List>
            {tournament.players.map((player, index) => (
              <ListItem key={index}>
                <ListItemText primary={player.name} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <Paper style={{ width: '100%', height: '50vh' }}>
          <Flow />
        </Paper>
      </Stack>
    </Container>
  )
}

const useSetupGames = (tournament?: Tournament['Custom']['WithPlayes']) => {
  const [upsertGames] = useUpsertGamesMutation()
  return useCallback(() => {
    console.log('!!!1')
    if (!tournament) return
    const beers = 3
    const nbrOfPlayers = tournament.players.length
    const preFinalMatchesPerPlayer = beers - 1
    const totalNbrOfPlays = preFinalMatchesPerPlayer * nbrOfPlayers
    const nbrPreFinalMatches = Math.ceil(totalNbrOfPlays / 4)
    const playersPerPreFinalMatch = splitInteger(
      totalNbrOfPlays,
      nbrPreFinalMatches
    )

    const firstMatches = playersPerPreFinalMatch.reduce(
      ({ matches, players }, curr) => {
        const matchPlayers = players.slice(0, curr)
        const rest = players.slice(curr, players.length - 1)
        console.log('!!!2', { matchPlayers, rest, curr, players })
        return { matches: [matchPlayers, ...matches], players: rest }
      },
      {
        matches: [] as (typeof tournament.players)[],
        players: tournament.players,
      }
    )

    // const firstMatches = tournament.players.reduce(
    //   ([x, ...rest], curr) => {
    //     if (x.length === 4) return [[curr], x, ...rest]
    //     return [[curr, ...x], ...rest]
    //   },
    //   [[]] as (typeof tournament.players)[number][][]
    // )
    console.log('!!!', firstMatches)
    // firstMatches.forEach((match) => {})
  }, [])
}
//
