import { Session } from '@supabase/supabase-js'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const ProtectedRoute = ({
  session,
  redirectPath = '/sign-in',
  children,
}: {
  session: Session | null
  redirectPath?: string
  children?: JSX.Element
}) => {
  if (!session) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}
