import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Input,
  InputLabel,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  useCreateTournamentMutation,
  useGetTournamentsQuery,
} from '../../hooks'
import { theme } from '../../theme'
import { createTournament } from '../../clients/supabaseClient'
import { TournamentModal } from './TournamentModal'
import { useNavigate } from 'react-router-dom'

export const TournamentsPage = () => {
  const { data, isLoading, isError, error } = useGetTournamentsQuery()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setCreateModalOpen(true)
  }

  const handleClose = () => {
    setCreateModalOpen(false)
  }

  return (
    <>
      <Typography variant="h1">Tournaments</Typography>
      <Box textAlign="end">
        <Button variant="contained" onClick={handleClickOpen}>
          New Tournament
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tournament</TableCell>
              <TableCell>Players</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  onClick={() => navigate(`/tournaments/${row.id}`)}
                  key={row.id}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>
                    {row.players.map((p) => p.name).join(', ')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TournamentModal onClose={handleClose} open={createModalOpen} />
    </>
  )
}
