import { Box, Container, Paper } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const SignInLayout = () => (
  <>
    <Container maxWidth="xs">
      <Box marginTop={20}>
        <Paper>
          <Box padding={4}>
            <Outlet />
          </Box>
        </Paper>
      </Box>
    </Container>
  </>
)
